import React from "react"
import Layout from "../components/layout"
import Loader from "../context/loader"

const Redirect = () => {
  return (
    <Layout>
      <Loader />
    </Layout>
  )
}

export default Redirect
