import { Skeleton } from "@chakra-ui/react"
import React from "react"

const Loader = () => (
  <>
    <div></div>
    <div className="page">
      <Skeleton height="20px" my="10px" />
      <Skeleton height="20px" my="10px" />
      <Skeleton height="20px" my="10px" />
    </div>
  </>
)
export default Loader
